<!-- 反馈管理-->
<template>
  <div>
    <div style="margin-bottom: 5px">
      <el-input
        v-model="title"
        placeholder="请输入标签名"
        suffix-icon="el-icon-search"
        style="width: 200px"
        @keyup.enter.native="loadPost"
      ></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="loadPost"
        >查询</el-button
      >
      <el-button type="success" @click="resetParam">重置</el-button>
      <el-button type="primary" style="margin-left: 5px" @click="add"
        >新增</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
      border
    >
      <el-table-column type="index" label="ID" width="60"> </el-table-column>
      <el-table-column prop="title" label="标签" width="180"> </el-table-column>
      <el-table-column prop="type" label="类型" width="180"> </el-table-column>
      <el-table-column prop="operate" label="操作">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="mod(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="del(scope.row.id)"
            style="margin-left: 5px"
          >
            <el-button slot="reference" size="small" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="标签" prop="title">
          <el-col :span="20">
            <el-input v-model="form.title"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-col :span="20">
            <el-select v-model="form.type" placeholder="请选择类型">
              <el-option label="题材" value="题材"></el-option>
              <el-option label="规格" value="规格"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "StorageManage",
  data() {
    name;
    return {
      tableData: [
        {
          id: 10000,
          title: "情感",
          type: "规格",
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      title: "",
      centerDialogVisible: false,
      form: {
        id: "",
        title: "",
        type: "",
      },
      rules: {
        title: [{ required: true, message: "请输入标签", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
      },
    };
  },
  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    }, // 编辑商品
    mod(row) {
      this.centerDialogVisible = true;

      this.form.id = row.id;

      this.form.title = row.title;
      this.form.type = row.type;
    },
    add() {
      this.centerDialogVisible = true;
      this.form.image = [];
      this.$nextTick(() => {
        this.form = {};
      });
    },
    save() {
      console.log(this.form);
      if (this.form.title == undefined) {
        this.$message({
          message: "请输入标签",
          type: "warning",
        });
      } else if (this.form.type == undefined) {
        this.$message({
          message: "请选择类型",
          type: "warning",
        });
      }
      this.$axios
        .post(this.$httpUrl + "/label/addUpdate/", this.form)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadPost();
            this.centerDialogVisible = false;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
    },
    del(id) {
      this.$axios
        .get(this.$httpUrl + "/label/remove/" + id)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadPost();
          } else {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        });
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageNum = 1;
      this.pageSize = val;
      this.loadPost();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.loadPost();
    },
    resetParam() {
      this.name = "";
    },
    loadPost() {
      this.tableData = [];
      this.$axios
        .post(this.$httpUrl + "/label/pagelist", {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          param: {
            title: this.title,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.tableData = res.data;
          } else {
            alert("获取数据失败");
          }
        });
    },
  },
  beforeMount() {
    this.loadPost();
  },
};
</script>

<style scoped>
</style>